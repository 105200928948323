<template>
  <div class="page">
    <!--s-->
	<div class="send-box">
	  <div class="send-block">
	    <!--快递配送-->
	    <div class="item" data-idx="0">
	      <div class="title-box flexcenter">
	        <div class="left flexcenter">
	          <div>快递配送</div>
	        </div>
	      </div>
	
	      <div class="address-box">
	        <div class="name">
	          <span>{{orderInfo.consignee}}</span> 
	          <span>{{orderInfo.telephone}}</span>
	        </div>
	        <div>地址：{{orderInfo.address}}</div>
	      </div>
	    </div>
	  </div>
	  <div class="addressBoxLine"></div>
	</div>
	
	<!--商品情况-->
	<div class="goods-list-box">
	  <div class="detail-box" v-for="(item,index) in orderInfo.orderGoods">
	    <div class="left">
	      <img class="full-img gd-img" mode="widthFix" :src="item.goods_image"></img>
	      <div class="sku-box">
	        {{item.sku_goods_name}}
	      </div>
	    </div>
	    <div class="right">
	
	      <div class="title-box">
	        <div class="title ellipsis2">{{item.goods_name}}</div>
	      </div>
	
	      <div class="piao-box flexcenter">
	        <div class="total">￥{{item.order_price}}</div>
	        <div class="addjian flexcenter">
	          <span class="count"><span class="tit">数量</span> x {{item.goods_number}}</span>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
	
	<!--商品情况-->
	<div class="paymethod-box nomarl-box">
	  <div class="item">
	    <div>订单编号</div>
	    <div class="nomarl">{{orderInfo.order_sn}}</div>
	  </div>
	  <div class="line"></div>
	  <div class="item">
	    <div>下单时间</div>
	    <div class="nomarl">{{orderInfo.add_time}}</div>
	  </div>
	  <div class="line"></div>
	  <div class="item">
	    <div>支付时间</div>
	    <div class="nomarl">{{orderInfo.pay_time_text}}</div>
	  </div>
	  <div class="line"></div>
	  <div class="item">
	    <div>支付状态</div>
	    <div class="nomarl">{{orderInfo.status_text}}</div>
	  </div>
	</div>
	
	<!--商品情况-->
	<div class="paymethod-box">
	  <div class="item paymentBox" bindtap="goSelPayment">
	    <div>支付方式</div>
	    <div class="payIconBox">
	      <div v-if="orderInfo.offline_pay_name" class="iconTxt">{{orderInfo.offline_pay_name}}</div>
	      <div v-else class="iconTxt">{{orderInfo.online_pay_name}}</div>
	    </div>
	  </div>
	  
	  <div class="line"></div>
	
	  <div class="item">
	    <div>商品金额</div>
	    <div class="nomarl">￥{{orderInfo.goods_amount}}</div>
	  </div>
	
	  <div class="item">
	    <div>运费</div>
	    <div class="nomarl">￥{{orderInfo.ship_amount}}</div>
	  </div>
	
	  <div class="item">
	    <div>订单金额</div>
	    <div class="red">￥{{orderInfo.order_amount}}</div>
	  </div>
	
	  <div class="line"></div>
	  <div class="item">
	    <div>订单备注</div>
	  </div>
	  <div class="liuyan">
	    {{orderInfo.user_other}}
	  </div>
	</div>
	
	<div class="bottom flexcenter" v-if="orderInfo.small_status=='1'">
	  <div></div>
	  <div class="cashier flexcenter">
	    <div class="cancel" @click="cancelOrder(orderInfo.order_id)">取消订单</div>
	    <div class="buy" @click="goSelPayment(orderInfo.order_id)">立即支付</div>
	  </div>
	</div>
	
	<van-popup round v-model="showSelPayment" bind:close="closeSelPayment">
	  <div class="selPaymentListBox">
	    <div class="paymentItem" @click="onSelPayment(index)" v-for="(item,index) in paymentList">
	      <div v-if="currentPayment.id==item.id" class="checkbox iconfont iconxuanzhong"></div>
	      <div v-else class="checkbox iconfont iconweixuanzhong"></div>
	      <div class="payment">
	        <div class="icon"><img :src="item.icon"></img></div>
	        <div class="name">{{item.name}}</div>
	      </div>
	    </div>
	  </div>
	</van-popup>
	<!--c-->
  </div>
</template>

<script>
  export default {
    name: 'orderdetails',
    data() {
      return {
        orderInfo:{},
        goPayOid:'',
        paymentList:[], //可用支付方式
        currentPayment:[],
		showSelPayment:false
      }
    },
    created() {
	  if(typeof this.$route.query.oid == "undefined")
	  {
		this.$toast("订单不存在");
		this.$router.back()
	  }
	  else
	  {
		this.getOrderInfo(this.$route.query.oid);
	  }
    },
    methods: {
		/**
         * 获取订单详情
         */
        getOrderInfo:function(oid){
          let that = this;
      	  this.post(this.API.orderDetails,{oid:oid}).then(
			response => {
				console.log(response);
				that.orderInfo = response.data.order;
				that.paymentList = response.data.payments;
			}
		  )
        },
		/**
		 * 取消订单 
		 */
		cancelOrder:function(oid){
		    if(oid==''||typeof(oid)=="undefined")
			{
				  this.$toast("缺少订单参数");
				  return;
			}
			let that = this;
			this.$dialog.confirm({ title: '提示',message: '确认取消此订单？'})
				.then(res => {
					that.post(that.API.cancelOrder,{oid:oid}).then(
						response => {
						if(res.data.err=='1'){
						  that.$toast(res.data.msg);
						}else{
						  that.$toast(res.data.msg);
						  that.getOrderList(1,true);
						}
					})
				})
		},
		/**选择支付方式 */
		goSelPayment:function(oid){
		  this.showSelPayment = true
		  this.goPayOid = oid
		},
		closeSelPayment:function(){
		  this.showSelPayment=false
		},
		onSelPayment:function(idx){
		  this.currentPayment = this.paymentList[idx]
		  this.showSelPayment = false
		
		  this.goPay()
		},
		/**
		 * 继续支付
		 */
		goPay:function(e){
		  let oid = this.goPayOid;
		  if(oid==''||typeof(oid)=="undefined")
		  {
		    this.$toast("缺少订单参数");
		    return;
		  }
		  let that = this;
		  
		  // 查看是否授权
		  this.post(this.API.goPayOrder, {oid:oid, payment:that.currentPayment.id})
		  	.then(response => {
		  	  if(response.data.err=='1')
		  	  {
		  		this.$toast(response.data.msg);
		  		return false;
		  	  }
		  	  else
		  	  {
		  		if(response.data.paydata.data.type=='local')
		  		{
		  		  this.$toast('订单支付成功!');
		  		  that.getOrderInfo(that.goPayOid);
		  		  return ;
		  		}
		  		
		  		let trans_no = response.data.paydata.data.trans_no;
		  		// TODO: 需要请求后端接口获取订单号
		  		upsdk.pay({
		  		  tn: response.data.paydata.data.result.tn,
		  		  success: function(res){
		  			console.log(res);
		  		    that.checkPay(trans_no)
		  		  },
		  		  fail: function(err){
		  		    // 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
		  			console.log(err);
		  			that.cancelPay(err)
		  		  }
		  		});
		  	}
		  })
		},
		checkPay:function(trans_no) {
				let that = this;
		  this.post(this.API.checkPay,{trans_no:trans_no})
					.then(response => {
					  if(response.data.err=='0') {
						that.$toast('支付成功');
					  }else{
						that.$toast(response.data.msg)
					  }
					  that.getOrderInfo(that.goPayOid);
		  })
		},
		cancelPay:function(res) {
				  console.log(res);
				  this.$toast("您取消了支付"+res.msg);
		}
    }
  };
</script>

<style lang="scss" scoped>
  .page {
      background-color: #F5F5F5;
      min-height: 100%;
      font-size: 32px;
      line-height: 1.6;
      font-family: "PingFang SC";
    }
    
    .send-box {
      margin: 24px;
      border-radius: 8px;
      background-color: #FFFFFF;
    }
    .send-box .send-block {
      padding:24px 20px;
    }
    .send-box .line {
      margin: 30px 0;
      height: 2px;
      background-color: #EEEEEE;
    }
    .send-box .item {
    
    }
    .send-box .item .title-box {
      justify-content: space-between;
      margin-bottom: 30px;
      color: #191919;
    }
    .send-box .item .title-box .left {
      font-size: 28px;
      font-weight: bold;
    }
    .send-box .item .title-box .left .iconfont {
      margin-right: 24px;
      width: 40px;
    }
    .send-box .item .title-box .right {
      font-size: 24px;
    }
    .send-box .item .address-box {
      margin-left: 10px;
      color: #777777;
      font-size: 24px;
    }
    .send-box .item .address-box .name {
      margin-bottom: 12px;
      font-size: 28px;
      font-weight: bold;
    }
    .send-box .item .address-box .spot-box .spot {
      margin-right: 24px;
      width: 112px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      background-color: #F5F5F5;
      border: 2px solid #C7C7C7;
      border-radius: 8px;
      font-size: 24px;
      color: #777777;
    }
    .send-box .item .address-box .spot-box .active {
      background-color: #FF9D10;
      color: #FFFFFF;
    }
    .addressBoxLine {
    	height:6px;
    	background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu0AAAAGCAYAAAB3oqAPAAAAAXNSR0IArs4c6QAABLFJREFUeAHtnG1olmUUx397b2vOtZUTJ7nFZuSk5iozSJPslUphUhjZKyPL8IPBsvcPFb04yA+SWYxeyEiKgtQgC6OSsKw1C2fkpM1y5sytOeeme+1cz7UPz6bQfR2CR905MHherv91Pfd9n3Ou87/P/17SkBijrasbVtXC9ztGfxPtfeFEeOJhmFIYbfyoUfV/w4t10HF81BcR3y64AJZMh9SkiID4Yf0DULseNm2J/zT66/E5UP0AlE+Ljokb2XwEnt0O+7riPgx4eaWc+uoKyE4LAMUP/fgzeOcjGJDzEGoZ6fDQYrj2qlBkbHz7MXj+R9jZpoIz9Rx4+nIoyNTh2foDrH4bunvCJ0hOhkW3wh3zISnc8Xr6JeQk3L5qCV/aISZm+WMvzdXh2dUIK9fCoX90E7hrvvQuSA93vEHJQG/9Ch/sgZNko//8PePE7apnwCzxfZXtb4UXXoXmfSo45WXwqMR8zjgVfkMTvL4T+gbD4Wnidi7XzS8Ox8YQnZJwat6A+gbdBEWTfa6fVKDCfyenvuYnONIbDndhdlsJ3H8RJIeHHPT2wWvr4Iut4Ys7RL4knBUPwrRSFb6xA56TlHNAtluNzZXtdXk5ZKYq0C7Q1m+E9zfAoMLxsiTJLrsXZkvCVVirpFh37LuV6WZ6Pjx5mVyCsxSLO8iWb2HNu3Bc4XgpKXB3JSy8SbV4l7hdTT1s+0sFZ3I2PDMTinTpBnbs8jF/uFP3A26ZB1WLpMCS8xBo/eJ2Ltd98nsgcHh4bgY8finMOE+HZ69ssC7XtxzQTXCFBNzyKimwZMNV2Ieyx70pe92AIuQy5HQvu1hO+wnr7mmWinkNtB464atIH8wRb3LBnBkeTXI9ee83WCd/biMPtSypF1wSu3pSKHJ4/EGpFt2xNzbpJiibKpu3JPH8XBX+8z+lZvxZ8oiiXk6Rzfs+2bxuL1EtDUbUjKgZUQsLHiNqiSVqcnNilo4rgBE1I2ph0e5HG1FLKFF7SohaXnhp6a9dooma3JzYpuQK8URtZNH+6Zf+LnNff7g7p8lUjn3dfE04VhCHhfC+VAd1B1VwinM8+yw8W4dnu1TLr9RK8Xo0fAJ3y6fyRrhnodzykeo50HqFda3+BTbvDQQOD8+Xmx7OmcvydHiMqCWOqP3hr70RtXDftY6addTCvUYQ1lE7fTtqRtRULo911M6Yjpov2nuOeVnAN6LL0FjBudKzWAolRRo0De1eFtEmLTON3TDFtw3Sw+tl3xp0chAnC9H05rOFJTxSBTMv0fx0WoQjODlMU6cKTsUE3y4aLxIBlRlRM6KmcZyx3lFzsog8ZUfNiJpJHxUxZ9JHkz4q3IaY9PHltdCm1CJdN9vLXpXSRycHcbIQTXkVkz4aURtx2VPPBI3P9eePOKbob9o7wDlzw+7omPiRpcWerEwQgZ3Cvt7vdczdonELNafhvPNCWCx/8jLcjKgZUbOOWljcWEdtbEsfE0jUnG7f6fdVZtJHkz6a9DEsdE5h6WPSUOWSIfXDGE4O4mQhCos9jPE/aXwUy5/2D2M8Jg9jVIzhhzGMqCm83oiaETUjamGBY0TNiFqCOmpG1OyfiZwsWf0Lz5t61CZi/VoAAAAASUVORK5CYII=);
    	background-size: 100% 6px;
    }
    
    .goods-list-box {
      background-color:#FFFFFF;
      margin:24px;
    }
    .detail-box {
      display: flex;
      justify-content: space-between;
      padding: 20px 24px;
      border-radius: 8px;
      background-color: #FFFFFF;
      border-bottom:2rpx solid #eeeeee;
    }
    .detail-box .left {
      margin-right: 24px;
      width: 160px;
    }
    .detail-box .left .sku-box {
      display: block;
      padding:2px 10px;
      font-size:26px;
      color:#FCC601;
      border-radius: 5px;
      float: right;
      margin-top: -60px;
      margin-right: 20px;
      background-color:#191919;
    }
    .detail-box .right {
      flex: 1;
    }
    .detail-box .right .title-box {
      margin-bottom: 22px;
      font-size: 28px;
      font-weight: bold;
      color: #191919;
      height: 78px;
      overflow: hidden;
    }
    
    .detail-box .right .piao-box {
      justify-content: space-between;
      font-size: 28px;
    }
    .detail-box .right .piao-box .total {
      font-size: 30px;
      color: #F84F41;
    }
    .detail-box .right .piao-box .addjian .count {
      width: 160px;
      text-align: center;
    }
    .detail-box .right .piao-box .addjian .count .tit {
      font-size:24px;
      color:#cccccc;
    }
    
    .paymethod-box {
      margin: 24px 24px 160px 24px;
      padding: 20px 24px;
      border-radius: 8px;
      background-color: #FFFFFF;
      font-size: 28px;
      color: #191919;
    }
    .paymethod-box .line {
      height: 2px;
      background-color: #EEEEEE;
    }
    .paymethod-box .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
    }
    .paymethod-box .red {
      font-weight: bold;
      color: #F84F41;
    }
    .paymethod-box .gray {
      color: #777777;
    }
    .paymethod-box .liuyan {
      padding:20px;
      height: 72px;
      border: 2rpx solid #F5F5F5;
    }
    .payment-icon {
      width: 40px;
      height: 40px;
    }
    
    .bottom {
      justify-content: space-between;
      position: fixed;
      z-index: 10;
      left: 0;
      right: 0;
      bottom: 0;
      height: 110px;
      background-color: #FFFFFF;
    }
    .bottom .heji {
      font-size: 24px;
      color: #191919;
    }
    .bottom .price {
      margin-right: 40px;
      font-size: 30px;
      color: #F84F41;
    }
    .bottom .buy {
      margin-right: 30px;
      width: 192px;
      height: 72px;
      line-height: 72px;
      border-radius: 36px;
      background-color: #FCC601;
      text-align: center;
      font-size: 30px;
      color: #FFFFFF;
    }
    
    .bottom .cancel {
      margin-right: 30px;
      width: 192px;
      height: 72px;
      line-height: 72px;
      border-radius: 36px;
      border: 2px solid #999999;
      background-color: #FFFFFF;
      text-align: center;
      font-size: 30px;
      color: #999999;
    }
    
    .paymethod-box .paymentBox {
      height: 80px;
    }
    .payIconBox {
      display: flex;
      line-height: 62px;
      vertical-align: middle;
    }
    .payIconBox .iconImg {
      margin-right: 6px;
      padding-top: 10px;
    }
    .payIconBox .iconTxt {
      padding-top: 2px;
    }
    .nomarl-box {
      margin-bottom: 0px;
    }
    .gd-img{
      height: 166px;
      background-color:#eeeeee;
    }
</style>
